<template>
    <div class="policy-wrapper">
        <div class="container policy-body">
            <h2 class="text-center">PRIVACY AND TERMS</h2>

            <p>
                <span class="tab-space">
                    <strong>DiscoverOhrid</strong> mobile application is owned by the Center for Integrated Development of Ohrid supported by GIZ and is a project to raise awareness of the cultural and historical significance of the sights in the Ohrid region, promotion, development and digitalization of the tourism sector.
                </span>
                <br>
                <span class="tab-space">Each user is responsible for the data they enter into the application and its confidentiality and truthfulness.</span>
                <br>
                <span class="tab-space">The mobile application will be available for all Android I IOS users.</span>
            </p>

            <br>

            <h4>Terms of use</h4>

            <p>
                <span class="tab-space">
                    <strong>DiscoverOhrid app</strong> reserves the right to terminate, modify or amended the terms of use at any time if it deems it necessary.
                </span>
            </p>

            <p>
                <span class="tab-space">
                    All changes that will be made will take effects after they are published on the website intended for the DiscoverOhrid application.
                </span>
            </p>

            <p>
                <span class="tab-space">This privacy policy may be amended at any time by posting the amended privacy policy text for the application or website. Changes to the privacy policy take effect immediately upon publication. It is the responsibility of each user to periodically review the Privacy Policy and read any changes.</span>
            </p>

            <p>
                <span class="tab-space">
                    The continued use of our application by the user, after the entry into force of such changes, implies that the user accepts the changes to the privacy policy.
                </span>                
            </p>

            <br>
            <h4>Privacy Policy</h4>

            <p>
                <span class="tab-space">
                    <strong>DiscoverOhridapp</strong> guarantees to all users of the mobile application the protection of personal data in accordance with the provisions of the Law on Personal Data Protection.
                </span>

                <span class="tab-space">
                    All users of the DiscoverOhrid application before registering are obliged to read the terms of use and by registering the mobile application they confirming that they understand the content and agree with the privacy policy.
                </span>
            </p>

            <br>
            <h4>Personal data protection</h4>

            <p>
                <span class="tab-space">
                    DiscoverOhridapp takes precautionary measures to protect the personal data of users in accordance with the positive legal regulations, in order to ensure confidentiality and protection of personal data of users.
                </span>
            </p>

            <p>
                <span class="tab-space">
                    The entire documentation for the software programs for personal data processing and all its changes are recorded and kept in accordance with the Rulebook on technical and organizational measures for ensuring confidentiality and protection of personal data processing of the Directorate for Personal Data Protection.
                </span>
            </p>

            <br>
            <h4>Access and corrections to your personal data</h4>

            <p>
                <span class="tab-space">
                    By logging in to your account you can change or delete your previously entered data during the first registration. The only thing you can not change is your email address. If you want to use your account with a different email address, you will have to re-create a new account with your new email address and your data. If you would like to deactivate or remove your account send a request to the following email address: discoverohridapp@gmail.com.
                </span>
            </p>

            <br>
            <h4>Contact</h4>

            <p>
                <span class="tab-space">
                    If you have any questions regarding the protection of your personal data, please contact us via our e-mail: discoverohridapp@gmail.com.
                </span>
            </p>
        </div>
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="scss" scoped>
    .tab-space {
        padding-left: 2em;
    }

    .policy-wrapper {
        width: 100%;
        height: 100vh;
        background-image: url('../assets/images/privacyBackgroundTiny.jpg');
        background-repeat: no-repeat;
        background-size: cover;

        .container {
            padding-top: 60px !important;
            padding-bottom: 60px !important;
            overflow: auto;
            padding: 1rem;
            border-radius: 10px;
            height: 100vh;
            background-color: rgba($color: #000000, $alpha: 0.6);
            color: white;
            font-size: 2rem;

            h2, h4 {
                color: white;
            }

            p {
                color: white;
            }
        }
    }

    ::-webkit-scrollbar {
        width: 12px;
    }
    
    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(32, 235, 191, 0.8); 
        border-radius: 10px;
    }
    
    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
    }
</style>